<template>
  <div class="paysuccess">
    <van-empty
      class="custom-image"
      description="支付成功"
    >
      <template #image>
        <van-button icon="success" type="primary" round  />
      </template>
    </van-empty>
    <div class="btn_list">
      <van-button type="primary" block @click="backup">再来一单</van-button>
      <van-button type="primary" block plain hairline @click="toOrder">查看订单</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaySuccess",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "支付成功");
  },
  methods: {
    backup() {
      this.$router.go(-1);
    },
    toOrder() {
      this.$router.replace({ name: "OrderList", query: { status: 2, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    }
  }
}
</script>
<style lang="less">
.paysuccess{
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  .custom-image{
    background-color: #fff;
    .van-empty__image{
      line-height: 160px;
      .van-button{
        width: 100px;
        height: 100px;
        padding: 0;
        font-size: 60px;
        vertical-align:middle;
      }
    }
    .van-empty__description{
      font-size: 20px;
      font-weight: bold;
      color: #07c160;
    }
  }
  .btn_list{
    padding: 0 20px;
    overflow: hidden;
    .van-button{
      margin-bottom: 30px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
}
</style>